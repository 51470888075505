import React, { Component } from 'react';
import { Picture } from 'react-responsive-picture';
import './App.css';

class App extends Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <Picture
            sources={[
              {
                srcSet: "logo-mobile.png",
                media: "(max-width: 720px)",
              },
              {
                srcSet: "logo-desktop.png",
              },
            ]}
            className="App-logo" alt="logo" />
          <a
            className="App-link"
            href="tel:4805893148"
            target="_blank"
            rel="noopener noreferrer"
          >480.589.3148</a>
          <a
            className="App-link"
            href="mailto:kevin@saltoftheearthbrewing.com"
            target="_blank"
            rel="noopener noreferrer"
          >kevin@saltoftheearthbrewing.com</a>
        </header>
      </div>
    );
  }
}

export default App;
